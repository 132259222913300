//@ts-nocheck
import appLocaleData from 'react-intl/locale-data/pt';
import ptMessages from '../locales/pt_BR.json';

const BrLang = {
  messages: {
    ...ptMessages,
  },
  locale: 'pt',
  data: appLocaleData,
};

export default BrLang;
