//@ts-nocheck
import BrLang from './entries/pt-BR';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  pt: BrLang,
};
addLocaleData(AppLocale.pt.data);

export default AppLocale;
