import React from 'react';
import { IntlProvider } from 'react-intl';
import AppLocale from '../languageProvider';
import { language } from '../config';
import { hasKeys } from '@l_ine/utils';

const currentAppLocale = hasKeys(AppLocale, language.locale) && AppLocale[language.locale];

const withLang = (ComposedComponent: any) => (props: any) => (
  <IntlProvider
    locale={currentAppLocale && currentAppLocale.locale}
    messages={currentAppLocale && currentAppLocale.messages}>
    <ComposedComponent {...props} />
  </IntlProvider>
);

export default withLang;
